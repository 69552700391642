<script>
import { SEARCH_STRING_LIMIT } from '@/config'
import { fetchArticleStatus } from '@/api'
import { downloadFile } from '@/helpers/file'
import { registerMatomoEvent } from '@/helpers/matomo'
import {
  Action,
  FeaturedNumber,
  NotificationList,
} from 'epmc-patterns/components/v2'

const maxDisplayedNumberOfIdsNotFound = 10

const isDOI = (id) => new RegExp('^10.{4,9}/[-._;()/:A-Z0-9]+$', 'i').test(id)

export default {
  metaInfo() {
    return {
      title: 'Article status monitor - Tools - Europe PMC',
    }
  },
  components: { Action, FeaturedNumber, NotificationList },
  data() {
    return {
      status: null,
      idInput: '',
      emptyInput: false,
      dislayedNumberOfIdsNotFound: maxDisplayedNumberOfIdsNotFound,
      showExamples: false,
    }
  },
  computed: {
    // status is present
    idFoundTotal() {
      return this.status.metrics.articlesWithStatusUpdate
    },
    idTotal() {
      return this.status.metrics.articlesQueried
    },
    idNotFoundTotal() {
      return this.status.metrics.articlesNotFound.length
    },
    idsNotFound() {
      return this.status.metrics.articlesNotFound
        .map((id) => id.extId)
        .slice(0, this.dislayedNumberOfIdsNotFound)
        .join(', ')
    },
    showMoreIdsNotFound() {
      const { dislayedNumberOfIdsNotFound, idNotFoundTotal } = this
      let text = ''
      if (idNotFoundTotal > maxDisplayedNumberOfIdsNotFound) {
        if (dislayedNumberOfIdsNotFound === maxDisplayedNumberOfIdsNotFound) {
          text = 'Show all'
        } else if (dislayedNumberOfIdsNotFound === idNotFoundTotal) {
          text = 'Show less'
        }
      }
      return text
    },
    updates() {
      const {
        status: { articlesWithStatusUpdate },
      } = this
      const updates = {
        preprint: [
          {
            title: 'Published<br />preprint',
            total: 0,
            query: '',
            link: true,
          },
          {
            title: 'New preprint<br />version',
            total: 0,
            query: '',
            link: true,
          },
          {
            title: 'Withdrawn<br />preprint',
            total: 0,
            query: '',
            link: true,
          },
          {
            title: 'Removed<br />preprint',
            total: 0,
            query: '',
            link: true,
          },
        ],
        other: [
          {
            title: 'Corected<br />article',
            total: 0,
            query: '',
            link: true,
          },
          {
            title: 'Retracted<br />article',
            total: 0,
            query: '',
            link: true,
          },
        ],
      }
      const { preprint: preprintUpdates, other: articleUpdates } = updates
      const updatesArr = [...preprintUpdates, ...articleUpdates]

      const joinQuery = (ids) => {
        const idGroups = ids.reduce((acc, id) => {
          let source = ''
          if (isDOI(id)) {
            source = 'DOI'
          } else if (isNaN(id)) {
            source = id.slice(0, 3)
          } else {
            source = 'MED'
          }
          const sameGroup = acc.find((group) => group.source === source)
          if (sameGroup) {
            sameGroup.ids.push(id)
            return acc
          } else {
            return acc.concat({ source, ids: [id] })
          }
        }, [])
        return idGroups
          .map((group) => {
            let str = ''
            if (group.source === 'PMC') {
              str = `PMCID:(${group.ids.join(' OR ')})`
            } else if (group.source === 'DOI') {
              str = `DOI:(${group.ids.join(' OR ')})`
            } else if (group.source === 'MED') {
              str = `(SRC:${group.source} AND EXT_ID:(${group.ids.join(
                ' OR '
              )}))`
            } else {
              str = group.ids.join(' OR ')
            }
            return str
          })
          .join(' OR ')
      }

      if (articlesWithStatusUpdate) {
        ;[
          'PUBLISHED_VERSION_AVAILABLE',
          'NEW_VERSION_AVAILABLE',
          'WITHDRAWN',
          'REMOVED',
          'CORRECTED',
          'RETRACTED',
        ].forEach((type, index) => {
          const articles = articlesWithStatusUpdate.filter((article) =>
            article.statusUpdates.includes(type)
          )
          updatesArr[index].title += articles.length === 1 ? '' : 's'
          let articleIds = []
          if (type === 'PUBLISHED_VERSION_AVAILABLE') {
            articleIds = articles.map((article) => {
              const parts = article.links
                .find((link) => link.rel === 'PUBLISHED_ARTICLE')
                .href.split('/')
              return parts[parts.length - 1]
            })
          } else if (type === 'NEW_VERSION_AVAILABLE') {
            articleIds = articles.map((article) => {
              const parts = article.links
                .find((link) => link.rel === 'NEW_VERSION')
                .href.split('/')
              return parts[parts.length - 1]
            })
          } else {
            articleIds = articles.map((article) => article.extId)
          }
          articleIds = [...new Set(articleIds)]
          updatesArr[index].total = articleIds.length
          updatesArr[index].query = joinQuery(articleIds)

          // can move into a method
          if (updatesArr[index].query.length > SEARCH_STRING_LIMIT) {
            updatesArr[index].link = false
          }
        })
      }

      const preprint = updatesArr.slice(0, 4).filter((update) => update.total)
      const other = updatesArr.slice(4, 6).filter((update) => update.total)
      const response = {}
      if (preprint.length) {
        response.preprint = preprint
      }
      if (other.length) {
        response.other = other
      }
      return response
    },
    notificationList() {
      const {
        idNotFoundTotal,
        updates: { preprint = [], other = [] },
      } = this
      const notificationList = []
      if (idNotFoundTotal) {
        notificationList.push({
          id: 'id-not-found',
          notificationStyle: 'warning',
        })
      }
      if ([...preprint, ...other].find((update) => !update.link)) {
        notificationList.push({
          id: 'too-many-characters',
          notificationStyle: 'warning',
        })
      }
      return notificationList
    },
  },
  watch: {
    idInput(val) {
      if (val) {
        this.emptyInput = false
      }
    },
  },
  methods: {
    viewResults() {
      registerMatomoEvent(
        'Article Status Monitor',
        'Click to view results',
        'View results in new window'
      )
    },
    exportStatus() {
      const {
        status: { articlesWithStatusUpdate },
      } = this

      let doc = 'Origin ID type,Origin ID,Status updates,Associated IDs\n'
      for (let i = 0; i < articlesWithStatusUpdate.length; i++) {
        const article = articlesWithStatusUpdate[i]
        const rowArr = [
          article.src,
          article.extId,
          article.statusUpdates.join(';'),
          article.links
            .filter((link) => link.rel !== 'SELF')
            .map((link) => {
              const parts = link.href.split('/')
              return parts[parts.length - 1]
            })
            .join(';'),
        ]
        doc += rowArr.join(',') + '\n'
      }
      downloadFile(doc, 'text/csv', 'europepmc')
      registerMatomoEvent(
        'Article Status Monitor',
        'Click "Export status updates"',
        'Export results'
      )
    },
    clear() {
      this.status = null
    },
    async submit() {
      const { idInput } = this

      if (idInput) {
        const getSRCByID = (id) => {
          if (isDOI(id)) {
            return 'DOI'
          }
          return isNaN(id) ? id.slice(0, 3) : 'MED'
        }
        // can be cleaner
        let ids = idInput
          .split(',')
          .map((id) => id.trim().split(';'))
          .reduce((sum, id) => [...sum, ...id], [])
          .map((id) => id.trim())
          .map((id) => id.trim().split(/\r?\n/))
          .reduce((sum, id) => [...sum, ...id], [])
          .map((id) => id.trim())
        ids = [...new Set(ids)]
        const prefixes = [
          'CTX',
          'NBK',
          'ETH',
          'AGR',
          'PMCID',
          'CBA',
          'HIR',
          'PAT',
          'PMID',
          'PPR',
        ]
        ids = ids.map((id) => {
          const prefix = prefixes.find((prefix) => id.startsWith(prefix + ':'))
          if (prefix) {
            return id.replace(prefix + ':', '').trim()
          } else {
            return id.trim()
          }
        })
        const data = ids.map((id) => ({
          src: getSRCByID(id),
          extId: id,
        }))
        const response = await fetchArticleStatus(data)
        this.status = response
        registerMatomoEvent(
          'Article Status Monitor',
          'Click "Submit IDs"',
          'Search for IDs'
        )
      } else {
        this.emptyInput = true
      }
    },
    showMoreOrLessIdsNotFound() {
      this.dislayedNumberOfIdsNotFound =
        this.dislayedNumberOfIdsNotFound === maxDisplayedNumberOfIdsNotFound
          ? this.idNotFoundTotal
          : maxDisplayedNumberOfIdsNotFound
    },
  },
}
</script>
<template>
  <div id="article-status-monitor-page">
    <div class="grid-row">
      <div class="col-16">
        <h1>Article status monitor</h1>
      </div>
    </div>

    <div class="grid-row">
      <div class="col-3">
        <br />
      </div>

      <div class="col-10">
        <template v-if="status">
          <h2>
            {{ idFoundTotal }} of {{ idTotal }}
            {{ idTotal === 1 ? 'ID has ' : 'IDs have' }} an update associated.
          </h2>
          <p>
            Find out more
            <a href="/AboutArticleStatusMonitor"
              >About the article status monitor</a
            >, how updates are obtained and what’s included in the data.
          </p>
          <notification-list
            v-if="notificationList.length"
            :notification-list="notificationList"
          >
            <template slot-scope="{ notification }">
              <template v-if="notification.id === 'id-not-found'">
                {{ idNotFoundTotal }}
                {{ idNotFoundTotal === 1 ? 'ID was ' : 'IDs were ' }} not found:
                {{ idsNotFound }}.
                <div
                  v-if="showMoreIdsNotFound"
                  id="show-more-or-less-ids-not-found"
                >
                  <hr />
                  <action @click.prevent="showMoreOrLessIdsNotFound">{{
                    showMoreIdsNotFound
                  }}</action>
                </div>
              </template>
              <template v-else-if="notification.id === 'too-many-characters'">
                One or more of the links below has been disabled because there
                are too many results to display. Please
                <action @click.prevent="exportStatus"
                  >export the status updates</action
                >
                to view the associated records.
              </template>
            </template>
          </notification-list>

          <template v-for="(value, key) in updates">
            <h3 :key="key + 'label'">
              {{ value.length === 1 ? 'Update' : 'Updates' }}
              {{ key === 'preprint' ? 'for preprints' : 'for articles' }}
            </h3>
            <div :key="key + 'number'" class="featured-numbers">
              <template v-for="update in value">
                <a
                  v-if="update.link"
                  :key="update.title"
                  :href="'/search?query=' + update.query"
                  target="_blank"
                  @click="viewResults"
                >
                  <featured-number
                    :number="update.total"
                    :below-text="update.title"
                    size="large"
                  />
                </a>
                <featured-number
                  v-else
                  :key="update.title"
                  :number="update.total"
                  :below-text="update.title"
                  size="large"
                  class="update-number"
                />
              </template>
            </div>
            <hr :key="key + 'separator'" class="category-separator" />
          </template>

          <div class="button-row">
            <input
              type="button"
              value="Export status updates"
              :disabled="!idFoundTotal"
              @click.prevent="exportStatus"
            />
            <input
              type="button"
              class="secondary"
              value="Enter IDs"
              @click.prevent="clear"
            />
          </div>
        </template>

        <template v-else>
          <h2>Enter preprint or article IDs to check for updates</h2>
          <ul>
            <li>
              Check to see if preprints have a more recent or published version,
              or have been withdrawn or removed.
            </li>
            <li>Check to see if articles have been retracted.</li>
          </ul>
          <p>
            <label class="semi-bold" for="id-input"
              >Enter a preprint or published article DOI, PMID, PMCID or
              PPRID</label
            ><br />
            Enter the identifiers only, separated by a comma, semi-colon or
            return.
            <action
              id="examples"
              icon-pos="right"
              @click.prevent="showExamples = !showExamples"
              >See examples<i
                slot="icon"
                :class="[
                  'fas',
                  showExamples ? 'fa-caret-up' : 'fa-caret-right',
                ]"
            /></action>
          </p>
          <p v-if="showExamples">
            A DOI should be entered as 10.1101/2020.02.03.932947; a PMID should
            be entered as 33074103; a PMC ID should be entered as PMC8763023;
            and a preprint ID should be entered as PPR178982. A file exported
            from the Europe PMC Export citations feature, using the ID list
            format, is also accepted.
          </p>
          <div>
            <textarea
              id="id-input"
              v-model.trim="idInput"
              placeholder="33541182, 34894394, PMC8553296, PMC8687514, PPR286317, PPR394069, 10.21203/rs.3.rs-36529/v1, 10.3322/caac.21660"
            ></textarea>
            <template v-if="emptyInput">
              <br />
              <span class="error"
                >To view results, you must enter an ID, in the format shown
                above.</span
              >
            </template>
          </div>
          <p>
            Find out more
            <a href="/AboutArticleStatusMonitor"
              >About the article status monitor</a
            >, how updates are obtained and what’s included in the data. If you
            want to check article or preprint updates programmatically, you can
            do so via the <a href="/RestfulWebService">Article status API</a>.
          </p>
          <div class="button-row">
            <input type="button" value="Submit IDs" @click.prevent="submit" />
          </div>
        </template>
      </div>

      <div class="col-3">
        <br />
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
#article-status-monitor-page {
  padding-top: $base-unit * 6.5;
  padding-bottom: $base-unit * 13;
  .featured-numbers {
    display: flex;
    flex-wrap: wrap;
    a {
      &:not(:last-child) {
        margin-right: $base-unit * 12;
      }
      &:visited {
        color: $epmc-darker-blue;
      }
    }
    .update-number:not(:last-child) {
      margin-right: $base-unit * 12;
    }
  }
  .category-separator {
    margin-top: $base-unit * 8;
  }
  textarea {
    width: 100%;
    height: $base-unit * 32;
  }
  .error {
    color: $epmc-red;
  }
  .button-row {
    margin-top: $base-unit * 8;
    input[type='button']:not(:last-child) {
      margin-right: $base-unit * 4;
    }
  }
  #show-more-or-less-ids-not-found {
    margin-top: $base-unit * 4;
    text-align: center;
  }
  #examples {
    display: inline-block;
  }
}
</style>
